.counters-request-content{
    .ngb-tp-input-container{
        width: 8.5rem !important;
    }
    .ngb-tp-input{
        background-color: $gallery;
        outline: thin;
        border-radius: 0.4rem;
        height: 4.8rem;
    }
}

.punch-in-out-conditions-content{
    .ngb-tp-input-container{
        width: 5.5rem !important;
    }
    .ngb-tp-input{
        background-color: $gallery;
        outline: thin;
        border-radius: 0.4rem;
        height: 3.8rem;
    }
}
/****
Typographic rules font-size.
Apply font-size class to div / any semantic elements.
***/

@mixin font-face($name, $file, $weight, $style) {
  @font-face {
    font-family: "#{$name}";
    src: url("/assets/fonts/#{$file}.ttf");
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face(Ubuntu-Bold, Ubuntu-Bold, normal, normal);
@include font-face(Ubuntu-Medium, Ubuntu-Medium, normal, normal);
@include font-face(Ubuntu-Regular, Ubuntu-Regular, normal, normal);
@include font-face(MOVE-Regular, MOVE-Regular, normal, normal);

.font-size-12 {
  font-size: 1.2rem;
}

.font-size-48 {
  font-size: 4.8rem;
}

label,
.font-size-19 {
  font-size: 1.9rem;
}

.font-size-22,
p.font-size-22 {
  font-size: 2.2rem;
}

.font-size-20,
p.font-size-20 {
  font-size: 2rem;
}

button,
.font-size-26 {
  font-size: 2.6rem;
}

h1.font-size-36,
.font-size-36 {
  font-size: 3.6rem;
}

h1.font-size-38,
.font-size-38 {
  font-size: 3.8rem;
}

.font-size-28,
h2 {
  font-size: 2.8rem;
}

.font-size-32 {
  font-size: 3.2rem;
}

.font-size-30 {
  font-size: 3rem;
}

.font-size-16 {
  font-size: 1.6rem;
}

.font-size-15 {
  font-size: 1.6rem;
}

.font-size-14 {
  font-size: 1.4rem;
}

.font-size-18 {
  font-size: 1.8rem;
}

.font-size-13 {
  font-size: 1.3rem;
}

.font-size-12 {
  font-size: 1.2rem;
}

.font-size-11 {
  font-size: 1.1rem;
}

.font-size-10 {
  font-size: 1rem;
}

.font-size-17 {
  font-size: 1.7rem;
}

.font-size-24 {
  font-size: 2.4rem;
}

.font-size-9 {
  font-size: .9rem;
}

.font-size-8 {
  font-size: .8rem;
}

.font-size-7 {
  font-size: .7rem;
}

.font-size-6 {
  font-size: .6rem;
}

.font-size-5 {
  font-size: .5rem;
}

/****
Typographic rules font-family.
Apply font-family class to div / any semantic elements.
***/

.ubuntu-regular {
  font-family: $font-normal;
}

.ubuntu-medium {
  font-family: $font-medium;
}

.ubuntu-bold {
  font-family: $font-bold;
}

// What's the default font of the app ?
body {
  font-family: $font-normal;
}


@import './src/scss/abstracts/variables';

.survey-progress-bar-container .progress-bar {
    background-color: $pomegranate;
}

.tooltip .tooltip-inner {
    font-size: 125%;
}

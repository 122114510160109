.cal-cell-row {
	height: 100%;
}

.cal-cell {
    color: $white;
    font-family: $font-bold;
    outline: none;
}

.cal-month-view {

    .cal-header {
        font-weight: bold;
        background-color: $casper;
        border-radius: .4rem;
        font-size: 1.2rem;
        margin-bottom: .2rem;
        padding-right: 1rem;
        padding-left: 1rem;
        &.cal-cell-row:hover {
            background-color: $casper;
        }
        &.cal-cell-row .cal-cell:hover,
        &.cal-header.cal-cell.cal-has-events.cal-open {
          background-color: $casper;
        }
    }
    .cal-day-cell {
        height: 7rem;
        min-height: 0px;
        cursor: pointer;
    }
    .cal-cell-top {
        min-height: 7rem;
    }
    .cal-days .cal-cell-row {
        border-bottom-color: #e1e1e1;
        margin-bottom: .5rem;
    }
    .cal-cell-row:hover {
        background-color: #fafafa2e;
    }
    .cal-cell-row .cal-cell:hover {
        background-color: #fafafa;
    }
    .cal-day-cell:not(:last-child) {
        border-right: none;
    }
    .cal-out-month .cal-cell-top {
        visibility: hidden !important;
    }
    .cal-today.cal-out-month {
        visibility: hidden;
    }
    .cal-days {
        padding: 2rem 1rem;
        border: none;
        @include respond-to-desktop() {
            min-height: 49rem;
        }
        @include respond-to-large-desktop() {
            min-height: 49rem;
        }
        .cal-cell-row {
            border: none;
        }
    }
    .cal-day-cell.cal-today {
        background: none ;
        .cal-cell-top.show-today {
            border: .1rem solid;
            border-radius: 50%;
            background-color: $pomegranate;
        }
    }
    .cal-today {
        .show-today {
            .day-number,.status {
                color: $white;
            }
            .point {
                background-color: $white;
            }
            .plus {
                &:before, &:after {
                    background: $white;
                }
            }
            
        }
    }
}

.shifts-container {
    height: 1rem;
    margin-left: 3px;
}

@mixin padding($direction, $distance) {
    padding-#{$direction} : $distance + rem;
}

@mixin border($direction, $width, $style) {
    border-#{$direction} : $width + rem $style;
}

.padding-top-10 {
    @include padding('top', 1)
}

.padding-bottom-10 {
    @include padding('bottom', 1)
}

.padding-left-20 {
    @include padding('left', 2)
}

.padding-16 {
    padding: 1.6rem;
}

.border-left-2 {
    @include border('left', 0.2, solid)
}
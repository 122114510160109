.app-carousel {
    
    &-calender {
        .swiper-slide {
            width: 5.6rem !important;
        }
    }
    &-cards,
    &-absence-counters,&-volunteer-counters {
        .swiper-button-prev, .swiper-button-next {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: $black;
            &:hover {
                background: $pomegranate;
            }
            &.swiper-button-disabled {
                display: none;
            }
            &:after {
                @extend .icon-24;
                content: '';
                position: absolute;
                top: 50%;
                transform: translateX(-50%)translateY(-50%);
            }
        }
        .swiper-button-prev:after {
            left: 46%;
            background-image: url("/assets/images/icons/swiper/arrow-left.svg"); 
        }
        .swiper-button-next:after {
            left: 54%;
            background-image: url("/assets/images/icons/swiper/arrow-right.svg"); 
           
        }
    }
    &-cards{
        margin-top: 1.6rem;
        .swiper-slide {
            width: 29.3rem !important;
        }
    }
    &-absence-counters {
        .swiper-slide {
            width: 20rem !important;
        }
    }
    &-volunteer-counters {
        .swiper-slide {
            width: 34rem !important;
        }
    }
}

.volonteer-counters .swiper-slide {
    min-height: 19rem;
}


@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/icons';
@import 'abstracts/colors';
@import 'abstracts/breakpoints';
@import 'abstracts/global';
@import 'abstracts/theme';
@import 'abstracts/forms';
@import 'abstracts/table';

@import 'base/reset';
@import 'base/typography';


@import 'layout/grid';
@import 'layout/utils';

@import "~@ng-select/ng-select/themes/default.theme.css";

@import 'overrides/common-overrides';
@import 'overrides/swiper';
@import 'overrides/calendar';
@import 'overrides/time-picker';
@import 'overrides/ng-select';
@import 'overrides/bootstrap';
@import "overrides/_material";

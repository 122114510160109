// =============================================================================
// Main container width
// =============================================================================
$large-destkop-container: 1200px;
$desktop-container: 1140px;
$tablet-container: 720px;
// obsolete (not to use anymore, it is replaced with $mobile-container-fix),
// because it doesn't take into consideration iphone 5 (a workaround was applied to the old code that used it)
$mobile-container: 345px;
// fixed container width that takes into consideration iphone 5
$mobile-container-fix: 305px;
$pwa-container: $mobile-container-fix;

// =============================================================================
// Responsive design breakpoints (based on bootstrap 4.1 in a way that suits the design of this project)
// https://getbootstrap.com/docs/4.1/layout/overview/
// =============================================================================
$large-desktop-breakpoint-start: 1200px;

$desktop-breakpoint-start: 992px;
$desktop-breakpoint-end: 1199.98px;

$tablet-breakpoint-start: 768px;
$tablet-breakpoint-end: 991.98px;

$mobile-breakpoint-end: 767.98px;

$small-mobile-breakpoint-end: 320px;

$small-height-device-end: 800px;



// =============================================================================
// Optimized Media Queries (based on bootstrap 4.1 in a way that suits the design of this project)
// These rules can be used on mobile first approach or destkop first approach.
// https://getbootstrap.com/docs/4.1/layout/overview/
// =============================================================================

// equivalent to bootstrap 4.1 mixin: @include media-breakpoint-between(md, xl) { ... }
// [768px 1199.98px]
@mixin respond-to-tablet() {
  @media only screen and (min-width: $tablet-breakpoint-start) and (max-width: $tablet-breakpoint-end) {
    @content;
  }
}

// equivalent to bootstrap 4.1 mixin: @include media-breakpoint-down(sm) { ... }
// [... 767.98px]
@mixin respond-to-mobile() {
  @media only screen and (max-width: $mobile-breakpoint-end) {
    @content;
  }
}

// equivalent to bootstrap 4.1 mixin: @include media-breakpoint-only(xl) { ... }
// [1600px ...]
// useful if you decided to use the mobile first approach
@mixin respond-to-large-desktop() {
  @media only screen and (min-width: $large-desktop-breakpoint-start) {
    @content;
  }
}

// equivalent to bootstrap 4.1 mixin: @include media-breakpoint-only(xl) { ... }
// [1200px ...]
// useful if you decided to use the mobile first approach
@mixin respond-to-desktop() {
  @media only screen and (min-width: $desktop-breakpoint-start) {
    @content;
  }
}

// equivalent to bootstrap 4.1 mixin: @include media-breakpoint-up(md, lg, xl) { ... }
// useful if you decided to use the mobile first approach
// [768px ...]
@mixin respond-to-tablet-and-above() {
  @media only screen and (min-width: $tablet-breakpoint-start) {
    @content;
  }
}
// [... 991.98px]
@mixin respond-to-tablet-and-below() {
  @media only screen and (max-width: $tablet-breakpoint-end) {
    @content;
  }
}

@mixin respond-to-small-mobile() {
  @media only screen and (max-width: $small-mobile-breakpoint-end) {
    @content;
  }
}

@mixin respond-to-small-desktop-and-below() {
  @media only screen and (max-width: $desktop-breakpoint-end) {
    @content;
  }
}

// Custom devices responsive web design
@mixin rwd($screen) {
  @media (max-width: $screen+'rem') {
    @content;
  }
}

@mixin custom-media($screen) {
  @media (max-width: $screen+'rem') {
    @content;
  }
}

// Vertical breakpoints
@mixin respond-to-small-height-device() {
  @media only screen and (max-height: $small-height-device-end) {
    @content;
  }
}


// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin respond-to-media-breakpoint-only($min, $max) {
  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}

// =============================================================================
// No bootstrap _reboot.scss for the header and the footer
// =============================================================================
@mixin no-bootstrap-reboot() {
  *,
  ::after,
  ::before {
    box-sizing: content-box;
    line-height: normal;
  }
}


.wrapper-content {
  overflow: hidden;
  margin: 0 auto;
  @include respond-to-mobile() {
    width: $mobile-container-fix;
  }
  @include respond-to-tablet() {
    width: $tablet-container !important;
  }
  @include respond-to-desktop() {
    width: $desktop-container !important;
  }
  @include respond-to-large-desktop() {
    width: $large-destkop-container !important;
  }
}

@import './src/scss/abstracts/variables';

.pointer {
  cursor: pointer;
}

.br-4 {
  border-radius: .4rem;
}

.badge-message {
  height: 1.6rem;
  width: 1.6rem;
  background-color: $pomegranate;
  color: $white;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  line-height: 1.6rem;
  display: inline-block;
  margin-left: 0.7rem;
}

.mobime-header {
    background:$white;
    padding:4rem 6.4rem;
    @include respond-to-tablet-and-below() {
        padding: 4rem 3rem;
    }
    @include respond-to-small-height-device() {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}
.mobime-page-container {
    padding:0 6.4rem 4rem;
    @include respond-to-tablet-and-below() {
        padding: 0 3rem 4rem;
    }
}

.tabs-content-container {
    padding:5rem 6.4rem;
    @include respond-to-tablet-and-below() {
        padding: 5rem 3rem;
    }
    @include respond-to-small-height-device() {
        padding-top:2rem;
        padding-bottom:2rem;
    }
}

.text-truncate-2 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.w-fix-content {
  width: fit-content;
}

.text-pre-wrap {
  white-space: pre-wrap;
}
@import './src/scss/abstracts/variables';

.btn {
    &:disabled {
        cursor: not-allowed;
        font-family: $font-normal;
    }
}

.btn-group-lg>.btn, .btn-lg {
    font-size: 1.5rem;
}

.btn-black {
    color: $white;
    background-color: $black;
    border-color: $black;
    &:hover:not(:disabled) {
        color: $white;
        background-color: $black;
        border-color: $black;
    }
    &:disabled {
        background:$gallery;
        border-color:$gallery;
        color:$grey;
    }
}

.btn-white {
    color: $black;
    background-color: $white;
    border-color: $lightGrey;
    &:hover:not(:disabled) {
        color: $white;
        background-color: $black;
        border-color: $black;
    }
    &:disabled {
        color:$grey;
    }
}

.btn-45 {
    height:4.5rem;
}

.form-control {
    font-size: 1.5rem;
}

input[type=radio], input[type=checkbox] {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.form-group {
    
    input[type="text"], 
    input[type="email"], 
    input[type="tel"], 
    input[type="password"], 
    input[type="number"] {
        border:0;
        height:4.8rem;
        &.error {
            border:.1rem solid $pomegranate;
            color:$pomegranate;
        }
    }
    
}
tr.mat-header-row {
  display: table-row !important;
  height: auto !important;
  background-color: #1e1e1e !important;
}

.mat-sort-header-content,
.mat-header-cell {
  color: white !important;
  font-weight: bold !important;
  text-align: left !important;
  vertical-align: middle !important;
}

tr.mat-row {
  height: auto !important;
}

.mat-cell,
.mat-sort-header-content,
.mat-header-cell {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 1.2rem !important;
  font-family: "Ubuntu-Regular" !important;
}

.mat-column-actions {
  width: 25px;
}

.mat-sort-header-arrow {
  color: white !important;
}
th.ng-star-inserted{
  height: auto !important;
  }

  td.ng-star-inserted{
  height: auto !important;
  }

  table.mat-table
  {
    th.ng-star-inserted{
      height: auto !important;
      }
      tr.ng-star-inserted{
        line-height: 30px!important;
        height: auto !important;
        }
    td.ng-star-inserted{
      height: auto !important;
      vertical-align: middle;
      }
  }

  div.mat-paginator-page-size{
    height: 6rem !important;
  }
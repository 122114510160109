@mixin icon_size($width, $height) {
  width: $width + rem;
  height: $height + rem;
  background-size: $width + rem $height + rem !important;
}

.icon {
  width: 5rem;
  height: 5rem;
  background-size: 5rem 5rem !important;
  background-repeat: no-repeat !important;

  /*   &.icon-10 {
    @include icon_size(1, 1);
  } */
}

.icon-16 {
  @include icon_size(1.6, 1.6);
}
.icon-25 {
  @include icon_size(2.5, 2.5);
}
.icon-45 {
  @include icon_size(4.5, 4.5);
}

/* .icon-bas {
  background: url('/assets/images/icons/bas.svg') ;
} */
.icon-6 {
  @include icon_size(0.6, 0.6);
}
.icon-8 {
  @include icon_size(0.8, 0.8);
}
.icon-10 {
  @include icon_size(1, 1);
}
.icon-12 {
  @include icon_size(1.2, 1.2);
}
.icon-15 {
  @include icon_size(1.5, 1.5);
}
.icon-16 {
  @include icon_size(1.6, 1.6);
}
.icon-17 {
  @include icon_size(1.7, 1.7);
}
.icon-18 {
  @include icon_size(1.8, 1.8);
}
.icon-19 {
  @include icon_size(1.9, 1.9);
}
.icon-20 {
  @include icon_size(2, 2);
}
.icon-24 {
  @include icon_size(2.4, 2.4);
}
.icon-25-16 {
  @include icon_size(2.5, 1.6);
}
.icon-28 {
  @include icon_size(2.8, 2.8);
}
.icon-30 {
  @include icon_size(3, 3);
}
.icon-32 {
  @include icon_size(3.2, 3.2);
}
.icon-36 {
  @include icon_size(3.6, 3.6);
}
.icon-40 {
  @include icon_size(4, 4);
}
.icon-44 {
  @include icon_size(4.4, 4.4);
}
.icon-48 {
  @include icon_size(4.8, 4.8);
}
.icon-56 {
  @include icon_size(5.6, 5.6);
}
.icon-68-48 {
  @include icon_size(6.8, 4.8);
}
.icon-80-48 {
  @include icon_size(8, 4.8);
}
.icon-80 {
  @include icon_size(8, 8);
}
.icon-100-20 {
  @include icon_size(10, 2);
}
.icon-100-24 {
  @include icon_size(10, 2.4);
}
.icon-100 {
  @include icon_size(10, 10);
}
.icon-181-44 {
  @include icon_size(18.1, 4.4);
}
.icon-204-45 {
  @include icon_size(20.4, 4.5);
}
.icon-204-37 {
  @include icon_size(20.4, 3.7);
}

.icon-menu-left {
  background: url("/assets/images/icons/menu-left.svg");
}

.icon-menu-right {
  background: url("/assets/images/icons/menu-right.svg");
}

// header
.icon-logout {
  background: url("/assets/images/icons/header/logout.svg");
}
.icon-logout-active {
  background: url("/assets/images/icons/header/logout-active.svg");
}
.icon-avatar {
  background: url("/assets/images/icons/header/avatar.svg");
}

//sidebar
.icon-accueil {
  background: url("/assets/images/icons/sidebar/accueil.svg");
}
.icon-accueil-active {
  background: url("/assets/images/icons/sidebar/accueil-active.svg");
}
.icon-symbole-transdev {
  background: url("/assets/images/icons/sidebar/symbole-transdev.svg");
}
.icon-folder {
  background: url("/assets/images/icons/sidebar/folder.svg");
}
.icon-file {
  background: url("/assets/images/icons/sidebar/file.svg");
}
.icon-logo-mobime {
  background: url("/assets/images/icons/sidebar/logo-mobime.svg");
}
.icon-planning {
  background: url("/assets/images/icons/sidebar/planning.svg");
}
.icon-planning-active {
  background: url("/assets/images/icons/sidebar/planning-active.svg");
}
.icon-letter {
  background: url("/assets/images/icons/sidebar/letter.svg");
}
.icon-letter-active {
  background: url("/assets/images/icons/sidebar/letter-active.svg");
}
.icon-counter {
  background: url("/assets/images/icons/sidebar/counter.svg");
}
.icon-counter-active {
  background: url("/assets/images/icons/sidebar/counter-active.svg");
}
.icon-documents {
  background: url("/assets/images/icons/sidebar/documents.svg");
}
.icon-documents-active {
  background: url("/assets/images/icons/sidebar/documents-active.svg");
}

.icon-function {
  background: url("/assets/images/icons/sidebar/custom-fucntion.svg");
}

.icon-function-active {
  background: url("/assets/images/icons/sidebar/custom-fucntion-active.svg");
}


.icon-coin {
  background: url("/assets/images/icons/sidebar/coin.svg");
}
.icon-coin-active {
  background: url("/assets/images/icons/sidebar/coin-active.svg");
}
.icon-door {
  background: url("/assets/images/icons/sidebar/door.svg");
}
.icon-door-active {
  background: url("/assets/images/icons/sidebar/door-active.svg");
}
.icon-hand {
  background: url("/assets/images/icons/sidebar/hand.svg");
}
.icon-hand-active {
  background: url("/assets/images/icons/sidebar/hand-active.svg");
}
.icon-change {
  background: url("/assets/images/icons/sidebar/change.svg");
}
.icon-change-active {
  background: url("/assets/images/icons/sidebar/change-active.svg");
}
.icon-disperse {
  background: url("/assets/images/icons/sidebar/disperse.svg");
}
.icon-disperse-active {
  background: url("/assets/images/icons/sidebar/disperse-active.svg");
}
.icon-team {
  background: url("/assets/images/icons/sidebar/team.svg");
}
.icon-team-active {
  background: url("/assets/images/icons/sidebar/team-active.svg");
}
.icon-annuaire {
  background: url("/assets/images/icons/sidebar/annuaire.svg");
}
.icon-annuaire-active {
  background: url("/assets/images/icons/sidebar/annuaire-active.svg");
}
.icon-sondage {
  background: url("/assets/images/icons/sidebar/sondage.svg");
}
.icon-sondage-active {
  background: url("/assets/images/icons/sidebar/sondage-active.svg");
}
.icon-main-courant {
  background: url("/assets/images/icons/sidebar/main-courant.svg");
}
.icon-main-courant-active {
  background: url("/assets/images/icons/sidebar/main-courant-active.svg");
}
.icon-ellipsis {
  background: url("/assets/images/icons/sidebar/ellipsis.svg");
}
.icon-ellipsis-active {
  background: url("/assets/images/icons/sidebar/ellipsis-active.svg");
}
.icon-external-link {
  background: url("/assets/images/icons/sidebar/external-link.svg");
}

// snackbar

.icon-checked {
  background-image: url("/assets/images/icons/snackbar/checked.svg");
}

.icon-cross {
  background-image: url("/assets/images/icons/snackbar/cross.svg");
}

.icon-info {
  background-image: url("/assets/images/icons/snackbar/info.svg");
}


.icon-leave {
  background-image: url("/assets/images/icons/snackbar/leave.svg");
}

//planning- card
.icon-title-rest {
  background-image: url("/assets/images/icons/card/text-rest.svg");
}
.icon-title-available {
  background-image: url("/assets/images/icons/card/text-available.svg");
}
.icon-title-unavailable {
  background-image: url("/assets/images/icons/card/text-unavailable.svg");
}
.icon-title-rest-en {
  background-image: url("/assets/images/icons/card/text-rest-en.svg");
  @include icon_size(20.4, 3.6); // too specific, no need to create custom repeatable icon-size
}
.icon-title-available-en {
  background-image: url("/assets/images/icons/card/text-available-en.svg");
  @include icon_size(19.5, 2.7); // too specific, no need to create custom repeatable icon-size
}
.icon-title-unavailable-en {
  background-image: url("/assets/images/icons/card/text-unavailable-en.svg");
  @include icon_size(24.1, 2.7); // too specific, no need to create custom repeatable icon-size
}
.icon-warning {
  background-image: url("/assets/images/icons/card/warning.svg");
}
.icon-clock {
  background-image: url("/assets/images/icons/card/clock.svg");
}
.icon-clock-red {
  background-image: url("/assets/images/icons/card/clock-red.svg");
}

.icon-calendar {
  background-image: url("/assets/images/icons/card/calendar-red.png");
}
.icon-half {
  background-image: url("/assets/images/icons/card/half.svg");
}
.icon-document {
  background-image: url("/assets/images/icons/card/document.svg");
}
.icon-alert {
  background-image: url("/assets/images/icons/card/alert.svg");
}
.icon-checked {
  background-image: url("/assets/images/icons/card/checked.svg");
}
.icon-vehicle {
  background-image: url("/assets/images/icons/card/vehicle.svg");
}

// Chat-message / observation
.icon-chat-message {
  background-image: url("/assets/images/icons/chat-message/icons8-chat-message-100.png");
}

// Forms add / delete

.icon-add-red-round {
  background-image: url("/assets/images/icons/forms/icon-add-red-round.svg");
}

.icon-delete-red-round {
  background-image: url("/assets/images/icons/forms/icon-delete-red-round.svg");
}

// planning-detail
.icon-arrow {
  background-image: url("/assets/images/icons/card/arrow.svg");
}
.icon-arrow-up {
  background-image: url("/assets/images/icons/planning-details/arrow-up.svg");
}
.icon-arrow-down {
  background-image: url("/assets/images/icons/planning-details/arrow-down.svg");
}
.icon-arrow-up-black {
  background-image: url("/assets/images/icons/planning-details/arrow-up-black.svg");
}
.icon-arrow-down-black {
  background-image: url("/assets/images/icons/planning-details/arrow-down-black.svg");
}
.icon-arrow-left {
  background-image: url("/assets/images/icons/planning-details/arrow-up.svg");
  transform: rotate(-90deg);
}
.icon-arrow-left-red {
  background-image: url("/assets/images/icons/arrow-up-red.svg");
  transform: rotate(-90deg);
}
.icon-arrow-right {
  background-image: url("/assets/images/icons/planning-details/arrow-up.svg");
  transform: rotate(90deg);
}
.icon-arrow-red-up {
  background-image: url("/assets/images/icons/planning-details/arrow-red.svg");
}
.icon-arrow-red-down {
  background-image: url("/assets/images/icons/planning-details/arrow-red.svg");
  transform: rotate(180deg);
}
.icon-arrow-red-left {
  background-image: url("/assets/images/icons/planning-details/arrow-red.svg");
  transform: rotate(-90deg);
}
.icon-arrow-red-right {
  background-image: url("/assets/images/icons/planning-details/arrow-red.svg");
  transform: rotate(90deg);
}
.icon-info-yellow {
  background-image: url("/assets/images/icons/planning-details/info-yellow.svg");
}
.icon-vehicle-grey {
  background-image: url("/assets/images/icons/planning-details/vehicle-grey.svg");
}
// modal
.icon-alert-red {
  background-image: url("/assets/images/icons/modals/alert_red.svg");
}
.icon-close {
  background-image: url("/assets/images/icons/cross.svg");
}
.icon-checked-green {
  background-image: url("/assets/images/icons/modals/checked-green.svg");
}

// messages

.icon-search {
  background-image: url("/assets/images/icons/message/search.svg");
}
.icon-star {
  background-image: url("/assets/images/icons/message/star.svg");
}
.icon-star-active {
  background-image: url("/assets/images/icons/message/star-active.svg");
}
.icon-ellipsis-vertical {
  background-image: url("/assets/images/icons/message/ellipsis-vertical.svg");
}
.icon-clock-white {
  background-image: url("/assets/images/icons/message/clock-white.svg");
}
.icon-message-not-read {
  background-image: url("/assets/images/icons/message/message-not-read.svg");
}
.icon-archiver {
  background-image: url("/assets/images/icons/message/archiver.svg");
}
.icon-message-exchanges-demande {
  background-image: url("/assets/images/icons/message/exchanges-demande.svg");
}
.icon-message-planing {
  background-image: url("/assets/images/icons/message/planing.svg");
}
.icon-message-sondages {
  background-image: url("/assets/images/icons/message/sondages.svg");
}
.icon-message-door {
  background-image: url("/assets/images/icons/message/door-white.svg");
}

// Absence

.icon-checked-white {
  background-image: url("/assets/images/icons/absence/checkmark-white.svg");
}
.icon-cross-white {
  background-image: url("/assets/images/icons/absence/cross-white.svg");
}

.icon-planing-white {
  background-image: url("/assets/images/icons/absence/icon-planing-white.svg");
}

// Prepay

.icon-arrow-right-grey {
  background-image: url("/assets/images/icons/prepay/arrow-right-grey.svg");
}
// Swap

.icon-swap-request {
  background-image: url("/assets/images/icons/swap/change.svg");
}
.icon-swap-request-horizontal {
  background-image: url("/assets/images/icons/swap/change.svg");
  transform: rotate(90deg);
}
.icon-radio {
  background-image: url("/assets/images/icons/swap/radio.svg");
}
.icon-radio-active {
  background-image: url("/assets/images/icons/swap/radio-active.svg");
}

button {
  outline: none;
}

.modal-content {
  border: none;
  border-radius: 1.2rem;
}

.collapsed {
  .swap-nav {
    width:calc(100% - 8rem)!important;
  }
}

.loader-modal {
  .modal-content {
      background-color: transparent;
      border: none;
      text-align: center;
  }
}

/*centre a block element without having to worry
 about if there is any top or bottom margin already applied.*/
 @mixin push--auto {
  margin: {
      left: auto;
      right: auto;
  }
}

/*When using ::before and ::after you'll always need these three,*/
 @mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

/*use this for creating scalable elements (usually images / background images) that maintain a ratio*/
@mixin responsive-ratio($x,$y, $pseudo: false) {
  $padding: unquote( ( $y / $x ) * 100 + '%' );
  @if $pseudo {
      &:before {
          @include pseudo($pos: relative);
          width: 100%;
          padding-top: $padding;
      }
  } @else {
      padding-top: $padding;
  }
}


/**use this for creating scalable elements (usually images / background images) that maintain a ratio*/
@mixin css-triangle($color, $direction, $size: 0.6rem, $position: absolute, $round: false){
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
      border-radius: 0.3rem;
  }
  @if $direction == down {
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-top: $size solid $color;
      margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: $size solid $color;
      margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-left: $size solid $color;
      margin-right: -$size;
  } @else if  $direction == left {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-right: $size solid $color;
      margin-left: -$size;
  }
}




/**set the style in all the separate formats**/
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


/**set centering elements for reset**/
@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }